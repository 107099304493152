@use 'sass:math';

/**
 * Site header
 */

$site-header-height: 92px;
$site-header-border: 5px;

.site-header {
    border-top: $site-header-border solid $grey-color;
    border-bottom: 1px solid $grey-color-light;
    min-height: $site-header-height;

    background: $background-color;
    // Positioning context for the mobile navigation icon
    position: relative;
    z-index: 2;

}

.site-title {
    line-height: $site-header-height;
    margin-bottom: 0;
    min-width: 300px;
    float: left;
}
.site-title__img {
    float: left;
    width: 60px;
    height: 60px;
    margin: 16px 16px 0 0;
}
.site-title__body {
    display: block;
    overflow: hidden;
}
.site-title__inner {
    vertical-align: middle;
    display: inline-block;
    line-height: initial;
}
.site-title__main {
    @extend %header;
    display: block;
    font-size: 36px;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}
.site-title__sub {
    color: $grey-color;
    display: block;
    font-size: $small-font-size;
}

.site-nav {
    float: right;
    line-height: $site-header-height;

    .menu-icon {
        display: none;
    }

    .nav__item {
        display: inline-block;

        // Gaps between nav items, but not on the last one
        margin-right: $spacing-half;

        &:last-child {
            margin-right: 0;
        }
    }

    .page-link {
        text-transform: uppercase;
        font-size: 26px;
        padding: 5px;

        color: $text-color;
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 25px;
        right: $spacing-half;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        .trigger.is-open {
            display: block;
            padding-bottom: 5px;
        }

        .nav__item {
            display: block;
            margin-right: 0;
            margin-left: $spacing-half;
        }

        .page-link {
            padding: 5px 10px;
        }
    }
}

// Subheader splash section
$subheader-height-no-title: 200px;
$subheader-height: 400px;
$subheader-height-lap: 300px;
$subheader-height-palm: 150px;

.site-subheader {
    width: 100%;
    text-align: center;
    position: relative;
    height: $subheader-height-no-title;
    line-height: $subheader-height-no-title;

    &:before {
        @extend %gradient;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }

    .wrapper {
        position: relative;
        z-index: 1;
    }
}

.site-subheader--title {
    height: $subheader-height;
    line-height: $subheader-height;

    @include media-query($on-laptop) {
        height: $subheader-height-lap;
        line-height: $subheader-height-lap;
    }
    @include media-query($on-palm) {
        height: $subheader-height-palm;
        line-height: $subheader-height-palm;
    }
}

.site-subheader__bg {
    background-image: url('/img/sully-splash-full.jpg');
    background-position: 50% 50%;
    background-size: cover;

    border-top: $site-header-border solid $grey-color-dark;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;

    height: $subheader-height + $site-header-height * 2;

    @include media-query($on-laptop) {
        background-image: url('/img/sully-splash-lap.jpg');
        height: $subheader-height-lap + $site-header-height * 2;
    }
    @include media-query($on-palm) {
        background-image: url('/img/sully-splash-palm.jpg');
        height: $subheader-height-palm + $site-header-height * 2;
    }
}
.site-subheader__title {
    display: inline-block;
    line-height: initial;
    vertical-align: middle;
    text-shadow: 1px 1px 0 rgba(black, 0.75), 0 0 8px rgba(black, 0.5);
    color: white;
    margin-bottom: 0;

    @include media-query($on-desk) {
        font-size: 64px;
    }

    @include media-query($on-laptop) {
        font-size: 48px;
    }
    @include media-query($on-palm) {
        font-size: 36px;
    }
}

/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid adjust-color($grey-color-dark, $lightness: -10%);
    background-color: $grey-color-dark;
    padding: $spacing-unit 0;
    color: $grey-color;
}

.footer-cols {
    margin-top: $spacing-unit*2;
    font-size: 15px;
}

.footer-heading {
    margin-top: $spacing-unit;
    margin-bottom: $spacing-half;
    color: $grey-color-light;
}
.footer-heading__sub {
    color: adjust-color($grey-color-dark, $lightness: -5%);
}

.footer-avatar {
    line-height: 120px;
}

.social-media-list {
    list-style: none;
    margin-left: 0;

    li {
        display: inline-block;
        margin: 0 $spacing-half $spacing-half 0;
    }
}

.site-nav--foot {
    .page-link {
        padding: 2px $spacing-unit 2px 0;
        display: inline-block;
        font-size: 24px;
        color: adjust-color($grey-color, $lightness: 10%);

        &:hover {
            color: $background-color;
        }
    }
}

@include media-query($on-palm) {

    .footer-heading,
    .footer-cols {
        margin-top: 0;
    }

}

.site-copyright {
    text-align: center;
    padding: $spacing-half 0;
    font-size: $small-font-size;

    border-top: 1px solid adjust-color($grey-color-dark, $lightness: -10%);
    background-color: adjust-color($grey-color-dark, $lightness: -5%);
    color: $grey-color-light;

    p {
        padding: $spacing-quart 0;
        margin: 0;
    }

    a {
        color: $grey-color;
    }
}

// Nav Link Styles
.page-link {
    @extend %header;
    line-height: $base-line-height;
    letter-spacing: 1px;
    display: block;
}

/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
    background: $background-color;
}

.page-content--alt {
    padding: $spacing-unit 0;
    background: $alt-background-color;
}

.page-content--work {
    padding-bottom: 0;
    position: relative;
}

.post-list {
    margin-left: 0;
    max-width: 44em;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-list--home {
    p {
        font-size: $small-font-size;
    }
}

.post-meta,
.rss-subscribe {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 36px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 48px;
    }
}

.post-author {
    max-width: 44em;
}

.post-content {
    margin-bottom: $spacing-unit;
    max-width: 44em;

    h2 {
        font-size: 48px;

        @include media-query($on-laptop) {
            font-size: 32px;
        }
    }

    h3 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 26px;
        }
    }

    h4 {
        font-size: 26px;
    }
}

// Homepage

$home-work-height: 600px;
$home-work-height-lap: 400px;
$home-work-height-mob: auto;

.home-work {
    @extend %clearfix;
    display: block;
    height: $home-work-height;
    line-height: $home-work-height;
}

.home-work__wrapper {
    position: relative;
}

.home-work__body {
    vertical-align: middle;
    display: inline-block;
    line-height: initial;
    width: 40%;
}

.home-work__img {
    position: absolute;
    bottom: 0;
}

.home-work__img--mob {
    margin-left: $spacing-unit;
    z-index: 1;
    left: 40%;
}
.home-work__img--desk {
    margin-left: $spacing-half;
    max-width: none;
    z-index: 0;
    left: 60%;
}

@include media-query($on-laptop) {

    .home-work {
        height: $home-work-height-lap;
        line-height: $home-work-height-lap;
    }

    .home-work__body {
        width: 50%;
    }

    .home-work__img--mob {
        width: 320px;
        left: 50%;
    }

    .home-work__img--desk {
        display: none;
    }
}

@include media-query($on-palm) {

    .home-work {
        height: auto;
        line-height: initial;
    }

    .home-work__body {
        padding-bottom: $spacing-unit;
        display: block;
        width: auto;
    }

    .home-work__img--mob {
        margin: auto;
        display: block;
        position: static;
    }
}

%gradient {
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.5)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
    background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
}

.popover__box--contact-form {
    max-width: 100%;
    max-height: 100%;

    width: 512px;
    height: 755px;
}

.form-page-wrapper {
    margin-top: -$spacing-quart;
    padding-left: $spacing-half;

    @include media-query($on-laptop) {
        padding-left: 0;
    }
}

.form-wrapper {
    margin-top: $spacing-unit;
    margin-left: -$spacing-half;
    margin-right: -$spacing-half;
}
