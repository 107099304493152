@use 'sass:math';

@charset "utf-8";

$nova-light: #C5D4DD;
$nova-dark: #3C4C55;

$nova-teal: #7FC1CA;
$nova-blue: #83AFE5;
$nova-yell: #DADA93;
$nova-green: #A8CE93;
$nova-purp: #9A93E1;
$nova-pink: #D18EC2;
$nova-orng: #F2C38F;
$nova-grey: #899BA6;
$nova-red: #DF8C8C;

$green: $nova-green;
$red: $nova-red;
$yellow: $nova-yell;
$orange: $nova-orng;

/*
// i still like these colors, let's hang on to them
$green: #84C618;
$red: #FF2929;
$yellow: #FFFF00;
$orange: #EF7300;
*/

// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.75;
$base-line-height: 1.45;
$header-font-family: 'Wire One', Impact, sans-serif;
$header-line-height: 1.2;

$spacing-unit:     30px;
$spacing-half:     math.div($spacing-unit, 2);
$spacing-quart:    math.div($spacing-unit, 4);

$text-color:       $nova-dark;
$background-color: #fdfdfd;
$alt-background-color: #ededed;
$brand-color:      $nova-blue;
$brand-alt-color:  $nova-purp;

$grey-color:       #828282;
$grey-color-light: adjust-color($grey-color, $lightness: 40%);
$grey-color-dark:  adjust-color($grey-color, $lightness: -25%);

// Width of the content area
$content-width:    1100px;

$on-palm:          600px;
$on-laptop:        900px;
$on-desk:          1100px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}


// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "_objects/_box",
        "_objects/_btn",
        "_objects/_col",
        "_objects/_form",
        "_objects/_gallery",
        "_objects/_intro",
        "_objects/_media",
        "_objects/_nav",
        "_objects/_popover",
        "layout",
        "states",
        "syntax-highlighting"
;
