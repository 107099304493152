.media {
    display: block;
}

.media__img {
    margin-right: $spacing-unit;
    float: left;
}

.media__body {
    overflow: hidden;
    display: block;
}
