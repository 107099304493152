/**
 * Syntax highlighting styles
 */
.highlight {
    @extend %vertical-rhythm;
    background: $nova-dark;
    border-radius: 3px;

    .highlighter-rouge & {
        background: $nova-dark;
        border-radius: 3px;
    }

    .c     { color: $nova-grey; font-style: italic } // Comment
    .err   { color: $nova-grey; background-color: $nova-red } // Error
    .k     { color: $nova-yell } // Keyword
    .o     { color: $nova-yell } // Operator
    .cm    { color: $nova-grey; font-style: italic } // Comment.Multiline
    .cp    { color: $nova-grey; font-weight: bold } // Comment.Preproc
    .c1    { color: $nova-grey; font-style: italic } // Comment.Single
    .cs    { color: $nova-grey; font-weight: bold; font-style: italic } // Comment.Special
    .gd    { color: $nova-grey; background-color: $nova-pink } // Generic.Deleted
    .gd .x { color: $nova-grey; background-color: $nova-red } // Generic.Deleted.Specific
    .ge    { font-style: italic } // Generic.Emph
    .gr    { color: $nova-red } // Generic.Error
    .gh    { color: $nova-light } // Generic.Heading
    .gi    { color: $nova-dark; background-color: $nova-teal } // Generic.Inserted
    .gi .x { color: $nova-dark; background-color: $nova-green } // Generic.Inserted.Specific
    .go    { color: $nova-light } // Generic.Output
    .gp    { color: $nova-light } // Generic.Prompt
    .gs    { font-weight: bold } // Generic.Strong
    .gu    { color: $nova-grey } // Generic.Subheading
    .gt    { color: $nova-grey } // Generic.Traceback
    .kc    { color: $nova-green } // Keyword.Constant
    .kd    { color: $nova-green } // Keyword.Declaration
    .kp    { color: $nova-green } // Keyword.Pseudo
    .kr    { color: $nova-green } // Keyword.Reserved
    .kt    { color: $nova-green } // Keyword.Type
    .m     { color: $nova-orng } // Literal.Number
    .s     { color: $nova-teal } // Literal.String
    .na    { color: $nova-orng } // Name.Attribute
    .nb    { color: $nova-purp } // Name.Builtin
    .nc    { color: $nova-green; font-weight: bold } // Name.Class
    .no    { color: $nova-orng } // Name.Constant
    .ni    { color: $nova-orng } // Name.Entity
    .ne    { color: $nova-orng; } // Name.Exception
    .nf    { color: $nova-blue; } // Name.Function
    .nn    { color: $nova-blue } // Name.Namespace
    .nt    { color: $nova-blue } // Name.Tag
    .nv    { color: $nova-blue } // Name.Variable
    .ow    { font-weight: bold } // Operator.Word
    .w     { color: $nova-grey } // Text.Whitespace
    .mf    { color: $nova-yell } // Literal.Number.Float
    .mh    { color: $nova-yell } // Literal.Number.Hex
    .mi    { color: $nova-teal } // Literal.Number.Integer
    .mo    { color: $nova-teal } // Literal.Number.Oct
    .sb    { color: $nova-red } // Literal.String.Backtick
    .sc    { color: $nova-red } // Literal.String.Char
    .sd    { color: $nova-red } // Literal.String.Doc
    .s2    { color: $nova-red } // Literal.String.Double
    .se    { color: $nova-red } // Literal.String.Escape
    .sh    { color: $nova-red } // Literal.String.Heredoc
    .si    { color: $nova-red } // Literal.String.Interpol
    .sx    { color: $nova-red } // Literal.String.Other
    .sr    { color: $nova-green } // Literal.String.Regex
    .s1    { color: $nova-teal } // Literal.String.Single
    .ss    { color: $nova-purp } // Literal.String.Symbol
    .bp    { color: $nova-light } // Name.Builtin.Pseudo
    .vc    { color: $nova-teal } // Name.Variable.Class
    .vg    { color: $nova-teal } // Name.Variable.Global
    .vi    { color: $nova-teal } // Name.Variable.Instance
    .il    { color: $nova-blue } // Literal.Number.Integer.Long
    .dl    { color: $nova-teal } // delimiters?
}
