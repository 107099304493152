/*
    Popovers
*/

$popover-shadow: 0 0 15px -3px rgba(0,0,0,0.35);
$popover-transition: background 0.15s ease-out;

.popover {
    // default styles
    -webkit-transtion: $popover-transition;
    -moz-transition: $popover-transition;
    transition: $popover-transition;
    pointer-events: none;
    display: table;
    overflow: hidden;
    visibility: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    background: rgba(white,0);

    &.is-showing {
        background: #666;
        background: rgba(210,210,210,0.6);
        pointer-events: auto;
        visibility: visible;
        z-index: 300;
    }
}
// lots of content, scrolls on smaller screens
// in this use case, .popover__box will require
// a fixed height, or max-height in px.
.popover--scroll {
    display: block;

    .popover__wrap,
    .popover__box {
        position: absolute;
        display: block;
        margin: auto;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }
}

    .popover__esc {
        display: none;
        position: absolute;
        z-index: 0;
        height: 100%;
        width: 100%;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;

        .popover.is-showing & {
            display: block;
        }
    }

    .popover__wrap,
    .popover__box {
        z-index: 5;
        position: relative;
        overflow: hidden;
        margin: auto;
    }
    .popover__wrap {
        pointer-events: none;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }

    $box-transition: opacity 0.15s ease-out, transform 0.15s ease-out;

    .popover__box {
        -webkit-transtion: $box-transition;
        -moz-transition: $box-transition;
        transition: $box-transition;

        transform: scaleX(0.85) scaleY(0.85);

        box-shadow: $popover-shadow;

        -webkit-overflow-scrolling: touch;
        background-color: $background-color;
        text-align: center;
        display: table;
        pointer-events: auto;
        opacity: 0;

        .popover--notransition & {
            -webkit-transition: none;
            -moz-transtion: none;
            transition: none;
            transform: none;
            opacity: 1;
        }

        .popover.is-showing & {
            transform: scaleX(1) scaleY(1);
            opacity: 1;
        }
    }

    .popover__box__close {
        border-radius: 50%;
        background-color: rgba(black,0.5);
        padding: $spacing-half;
        position: absolute;
        width: $spacing-unit;
        height: $spacing-unit;
        line-height: $spacing-unit;
        top: $spacing-half;
        right: $spacing-half;

        cursor: pointer;
        z-index: 2;

        & > svg {
            height: $spacing-unit;
            width: $spacing-unit;
            position: relative;
            top: -3px;

            path {
                fill: rgba(250,250,250,0.85);
            }
        }
    }

    .popover__box__nav {
        background-color: rgba(black,0.5);
        padding: $spacing-half $spacing-quart;
        width: $spacing-unit;
        height: $spacing-unit;
        line-height: $spacing-unit;
        margin-top: -$spacing-unit;
        position: absolute;

        cursor: pointer;
        z-index: 2;

        &--left {
            border-radius: 0 50% 50% 0;
            top: 50%;
            left: 0;
        }

        &--right {
            border-radius: 50% 0 0 50%;
            top: 50%;
            right: 0;
        }

        & > svg {
            height: $spacing-unit * 1.5;
            width: $spacing-unit * 1.5;
            position: absolute;
            margin: auto;
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;

            path {
                stroke: rgba(250,250,250,0.85);
            }
        }
    }

    .popover__box__content {
        max-height: 100%;
        height: 100%;

        // do not capture scroll unless showing
        .is-showing & {
            overflow: auto;
        }
    }
