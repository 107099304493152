
.col-wrapper {
    @extend %clearfix;
    margin-left: -$spacing-half;
    margin-top: $spacing-half;
}

.col {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;

    margin-bottom: $spacing-half;
    padding-left: $spacing-half;
}

.col--right {
    float: right;
}

.col--sixty {
    width: 60%;
}

.col--half {
    width: 50%;
}

.col--forty {
    width: 40%;
}

// footer columns
.col--foot-1 {
    width: 20%;
}

.col--foot-2 {
    width: 35%;
}

.col--foot-3 {
    width: 45%;
}

@include media-query($on-laptop) {
    .col--foot-1 {
        padding-right: $spacing-unit*2;
        width: auto;
    }
    .col--foot-2 {
        float: none;
        width: auto;
        display: block;
        overflow: hidden;
    }
    .col--foot-3 {
        margin-top: $spacing-unit;
    }

    .col--half--lap {
        width: 50%;
    }

    .col--whole--lap,
    .col--foot-3 {
        width: 100%;
        clear: both;
    }

    .col--left--lap {
        float: left;
    }

    .col--right--lap {
        float: right;
    }
}

@include media-query($on-palm) {
    .col {
        float: none;
        width: 100%;
    }
}



