.box {
    background-color: $background-color;
    box-shadow: 0 0 1px $grey-color, 0 4px 0 $grey-color-light;

    padding: $spacing-unit;
    padding-bottom: $spacing-half;

    display: block;
}

.box--ctr {
    text-align: center;
}
