$intro-media-size: 128px;
$intro-media-size-sm: 75px;

.intro {
    display: block;
    font-size: 15px;

    p {
        max-width: 36em;
    }
}

.intro__media {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: $spacing-unit;
    margin-bottom: 5px;
    position: relative;
    float: left;
    height: $intro-media-size;
    width: $intro-media-size;

    &:before {
        content: "";
        z-index: 1;
        box-shadow: 1px 1px 2px rgba(white,0.35), inset 1px 1px 3px rgba(black,0.5);
    }
}

.intro__media--sm {
    height: $intro-media-size-sm;
    width: $intro-media-size-sm;
}

.intro__media:before,
.intro__img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}

.intro__body {
    display: block;
    overflow: hidden;
}

.intro__title {
    font-size: 42px;
    margin-bottom: 0;
}

.intro__sub {
    font-style: italic;
    color: $grey-color;
}

