
.is-hidden {
    display: none !important;
}

@include media-query($on-laptop) {
    .hide--lap {
        display: none;
    }
    .small--lap {
        font-size: $small-font-size;

        h1 &,
        h2 & {
            font-size: 0.65em;
        }
    }
}

@include media-query($on-palm) {
    .hide--palm {
        display: none;
    }
}
