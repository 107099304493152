$btn-box-shadow: 1px 1px 0 rgba(white,0.5), inset 1px 1px 1px rgba(black,0.15);

.btn, %btn {
    -webkit-appearance: none;
    appearance: none;
    // Standard display types to make them behave correctly
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    position: relative;
    text-decoration: none !important;
    cursor: pointer;

    // Normalize sizing
    border: none;
    outline: none;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 $spacing-unit;
    line-height: $base-line-height*2;

    // Basic styling to make them look nice
    box-shadow: $btn-box-shadow;
    background-color: $grey-color;
    color: $background-color;

    // Round corners
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    border-radius: 0.25em;

    // This is important to prevent weirdness if you assign a width
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -ms-box-sizing: content-box;
    -o-box-sizing: content-box;
    box-sizing: content-box;

    // Users love to click buttons, but double-clicking can select text. Stop that
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;

    &:visited {
        color: $background-color;
    }

    &:hover {
        box-shadow: 1px 1px 2px rgba(white,0.75), inset 1px 1px 1px rgba(black,0.15);
        background-color: adjust-color($grey-color, $lightness: -2.5%);
        color: $background-color;
    }

    &:active {
        box-shadow: -1px -1px 0 rgba(white,0.75), inset -1px -1px 1px rgba(black,0.25);
        background-color: adjust-color($grey-color, $lightness: -5%);
    }

    &:disabled,
    &--disabled {
        &, &:hover, &:active {
            box-shadow: $btn-box-shadow;
            background-color: $grey-color-light;
            color: adjust-color($grey-color, $lightness: 30%);
        }
    }
}

.btn--foot {
    box-shadow: 1px 1px 0 rgba(black,0.5), inset 1px 1px 2px rgba(white,0.1);

    &:hover {
        box-shadow: 1px 1px 2px rgba(black,0.75), inset 1px 1px 2px rgba(white,0.1);
    }

    &:active {
        box-shadow: -1px -1px 0 rgba(black,0.75), inset -1px -1px 2px rgba(white,0.15);
    }
}

.btn--brand {
    background-color: $brand-color;

    &:hover {
        background-color: adjust-color($brand-color, $lightness: -2.5%);
    }

    &:active {
        background-color: adjust-color($brand-color, $lightness: -5%);
    }
}

.btn--fill {
    width: 100%; // for input elements
    display: block;
    padding-left: 0;
    padding-right: 0;
}

