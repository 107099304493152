// Contact form

$form-font-size: 14px;
$form-invalid-border: $orange;
$form-invalid-bg: adjust-color($orange, $lightness: 45%);
$form-valid-border: $green;
$form-valid-bg: adjust-color($green, $lightness: 45%);
$form-input-transition: background 0.2s ease-in-out, border 0.2s ease-in-out;

.form {
    display: block;
    position: relative;
    text-align: left;
}

.form__hidden {
    display: none;
}

.form__row {
    display: block;
    position: relative;
    padding: $spacing-half;
}

.form__row--cols {
    padding-top: 0;
    padding-bottom: 0;
}

.form__row--req {
    &:before {
        content: "Required";
        color: $grey-color-light;
        text-transform: uppercase;
        font-size: 11px;
        padding-top: 2px;

        position: absolute;
        top: $spacing-half;
        right: $spacing-half;

        .is-dirty.is-invalid & {
            color: $red;
        }
    }
}

.form__lbl {
    display: block;
    font-size: $small-font-size;
    margin: 0 0 $spacing-quart;
}

.form__err {
    display: none;
    padding: $spacing-quart $spacing-half;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    font-size: $small-font-size;
    background-color: $form-invalid-border;
    color: #fff;
}

.form__txt,
.form__inp {
    width: 100%;
    outline: none;
    display: block;
    box-sizing: border-box;
    font-size: $form-font-size;
    border-radius: 4px;
    border: 1px solid adjust-color($grey-color, $lightness: 20%);
    -webkit-transtion: $form-input-transition;
    -moz-transition: $form-input-transition;
    transition: $form-input-transition;

    &.is-touched.is-invalid,
    &:user-invalid {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        border-color: $form-invalid-border;
        background-color: $form-invalid-bg;

        &::-webkit-input-placeholder {
           color:  $form-invalid-border;
        }

        &:-moz-placeholder { /* Firefox 18- */
           color:  $form-invalid-border;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
           color:  $form-invalid-border;
        }

        &:-ms-input-placeholder {
           color:  $form-invalid-border;
        }

        & + .form__err {
            display: block;
        }
    }
    &.is-touched.is-valid.is-valid-required,
    &:user-valid {
        border-color: $form-valid-border;
        background-color: $form-valid-bg;
        color: $form-valid-border;
    }
}

.form__txt {
    min-height: $spacing-unit * 3;
    padding: $spacing-half;
    max-width: 100%;
}

.form__inp {
    height: $spacing-unit*1.5;
    padding: 0 $spacing-half;
}

.form__btn {
    font-size: $base-font-size;
}

.form--attribution {
    display: block;
    font-size: $small-font-size;
    padding: $spacing-half;

    &, a {
        color: $grey-color;
    }
}
